<div class="flight-info-headline-container pb-2 roboto-font dark-blue ms-2 me-2">
  <span *ngIf="isArrivalLeg">Arriving Flight</span>
  <span *ngIf="!isArrivalLeg">Departing Flight</span>

  <span *ngIf="leg" class="margin-left-30">
          {{leg?.departureStation ? (leg?.departureStation + ' ' + (leg?.std | date:'HH:mm':timezone) + ' - ' + leg?.arrivalStation + ' ' + (leg?.sta | date:'HH:mm':timezone)) : '-'}}
        </span>
  <span *ngIf="!leg" class="margin-left-30">-</span>
</div>

<div class="flight-info-data-container">
  <div class="flex flex-column">
    <app-icon-text-item-small iconHeader="icon-plane-front"
                              [text]="leg?.acRegistration"
    ></app-icon-text-item-small>
    <div class="ac-type">({{leg?.acType}})</div>
  </div>

  <ng-container *ngIf="isArrivalLeg">
    <app-icon-text-item-small textHeader="ETD"
                              [text]="leg?.etd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.etd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.etd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="ATD"
                              [text]="leg?.atd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.atd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.atd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="TAKE OFF" [text]="leg?.takeOffTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.takeOffTime, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.takeOffTime, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="LAND"
                              [text]="leg?.landingTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.sta, leg?.landingTime, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.sta, leg?.landingTime, timezone)"
    ></app-icon-text-item-small>

  </ng-container>

  <ng-container *ngIf="!isArrivalLeg">
    <app-icon-text-item-small textHeader="ATD"
                              [text]="leg?.atd | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.atd, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.atd, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="TAKE OFF"
                              [text]="leg?.takeOffTime | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.takeOffTime, leg?.std, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.std, leg?.takeOffTime, timezone)"
    ></app-icon-text-item-small>

    <app-icon-text-item-small textHeader="ETA"
                              [text]="leg?.eta | date:'HH:mm':timezone"
                              [indicator]="0 !== calcDateDifferenceInDays(leg?.eta, leg?.sta, timezone)"
                              indicatorType="text"
                              [indicatorText]="calcDateDifferenceString(leg?.sta, leg?.eta, timezone)"
    ></app-icon-text-item-small>
  </ng-container>

  <app-icon-text-item-small textHeader="{{isArrivalLeg ? ' IN ' : 'OUT'}} PAX" [text]="paxTotal"></app-icon-text-item-small>
  <app-icon-text-item-small textHeader="DL" [text]="isArrivalLeg ? arrivalDelayInMinutes > 0 ? (arrivalDelayInMinutes | timeFromMinutes) : '-' : departureDelayInMinutes > 0 ? (departureDelayInMinutes | timeFromMinutes) : '-'" [indicator]="(departureNumberOfDelays+arrivalNumberOfDelays) > 0" indicatorType="text"
                            [indicatorText]="(departureNumberOfDelays+arrivalNumberOfDelays).toString()"></app-icon-text-item-small>

  <app-icon-text-item-small *ngIf="!isArrivalLeg" textHeader="CTOT" [text]="leg.ctot | date: 'HH:mm':'+0000' "></app-icon-text-item-small>
</div>
