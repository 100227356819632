import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PairProcessesService} from '../../../services/pair-processes.service';
import {forkJoin, interval, Subject, switchMap, takeUntil, timer} from 'rxjs';
import {IPairsProcessesModel} from '../../models/pair-processes.model';
import {ProcessesService} from '../../../services/processes.service';
import {IProcessesModel} from '../../models/processes.model';
import {ProcessDetailsModel} from './process-details.model';
import * as dayjs from 'dayjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RampViewProcessesComponent} from '../ramp-view-processes/ramp-view-processes.component';
import {GseService} from '../../../services/gses.service';
import {IGsesModel} from '../../models/gses.model';
import {IPairDetail} from '../../models/pair-detail.model';
import {ToastService} from '../../../services/toast.service';
import {PairTurnaroundStatesService} from '../../../services/pair-turnaround-states.service';
import {IPairTurnaroundStatesModel} from '../../models/pair-turnaround-states.model';
import {Access} from "../../enums/permissions.enums";


@Component({
  selector: 'app-pair-processes',
  templateUrl: './pair-processes.component.html',
  styleUrls: ['./pair-processes.component.scss']
})
export class PairProcessesComponent implements OnInit, OnDestroy {

  @Input() pair: IPairDetail;
  @Input() user: any;
  @Input() acTypeId: number;
  @Input() airportId: number;
  @Input() pageAccess: Access;

  pairProcesses: IPairsProcessesModel[];
  processes:IProcessesModel[];
  gses: IGsesModel[] = [];
  processDetailsArray: ProcessDetailsModel[] = [];
  unsubscribe$ = new Subject();
  pairTurnaroundState: IPairTurnaroundStatesModel;
  turnaroundState: string;


  constructor(
    private pairProcessesService: PairProcessesService,
    private processService: ProcessesService,
    private modalService: NgbModal,
    private gseService: GseService,
    private toastService: ToastService,
    private pairTurnaroundService: PairTurnaroundStatesService,
    ) { }

  async ngOnInit(): Promise<void> {
    if (!this.pageAccess) {
      return;
    }
    forkJoin([
      this.pairProcessesService.getPairProcesses({pairId: this.pair?.id, isActive: true}),
      this.processService.getProcesses({isActive: true}),
      this.gseService.fetchGses({ isActive: true, isNone: false}),
      this.pairTurnaroundService.getPairTurnaroundState({isActive: true, pairId: this.pair?.id})
    ]).subscribe((results) => {
      this.pairProcesses = results[0];
      this.processes = results[1];
      for (const process of this.processes) {
        const filteredGse = results[2].find((gse) => gse.acTypeId === this.acTypeId && gse.airportId === this.airportId && gse.processId === process.id);
        if (filteredGse) {
          this.gses.push(filteredGse);
          continue;
        }
        this.gses.push(results[2].find((gse) => gse.processId === process.id))
      }
      this.pairTurnaroundState = results[3][results[3].length -1];
      this.createProcessDetailsArray();
      this.getTurnaroundState();
      console.log('Pair states', this.pairTurnaroundState);
      console.log('State', this.turnaroundState);

    });
    interval(1000).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
       this.checkProcesses();
     });
  }

  hasPendingRequest(processId: number): boolean {
    return this.pairProcessesService.pendingRequests.has(processId);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  getTurnaroundState () {
    const tId = this.pairTurnaroundState?.turnaroundStateId;
    console.log(tId)
    if (tId) {
      if (tId === 3) {
        this.turnaroundState = 'IN_PROGRESS';
      } else if (tId === 4) {
        this.turnaroundState = 'PAUSED';
      } else if (tId === 5) {
        this.turnaroundState = 'RESUMED';
      } else if (tId === 6) {
        this.turnaroundState = 'FINISHED';
      }
    } else {
      this.turnaroundState = 'NOT_STARTED'
    }
  }

  private checkProcesses() {
    for (const process of this.processDetailsArray) {
      if (process.startedAt !== null && process.finishedAt === null) {
        process.secondsCount = dayjs.utc().diff(process.startedAt, 'seconds');
      }
    }
  }

  async createProcessDetailsArray() {
    for (const pairProcess of this.pairProcesses) {
      const processDetails: ProcessDetailsModel = {};
      processDetails.startedAt = pairProcess.startTime;
      processDetails.finishedAt = pairProcess.endTime;
      processDetails.processId = pairProcess.processId;
      processDetails.processName = this.processes.find((process) => process.id === pairProcess.processId).title;

      if (pairProcess.startTime !== null && pairProcess.endTime !== null) {
        processDetails.secondsCount = dayjs(pairProcess.endTime).diff(pairProcess.startTime, "seconds");
      } else if (pairProcess.startTime !== null && pairProcess.endTime === null) {
        processDetails.secondsCount = dayjs().utc().diff(pairProcess.startTime, "seconds");
      } else {
        processDetails.secondsCount = 0;
      }

      this.processDetailsArray.push(processDetails);
    }
  }

  async startTime(processId: number, event: MouseEvent) {
    const index = this.processDetailsArray.findIndex((processDetail) => processDetail.processId === processId)
    const element = event.target as HTMLButtonElement;
    if (this.pairProcessesService.pendingRequests.has(processId)) {
      this.pairProcessesService.abortPairProcessSaving(processId);
      element.classList.remove('loading');
      return;
    }
    element.classList.add('loading');
    const startTime = dayjs.utc().toDate();
    this.pairProcessesService.pendingRequests.set(processId, timer(5000).pipe(switchMap(() => {
      element.disabled = true;
      element.classList.add('btn-disabled');
      return this.pairProcessesService.savePairProcess({
        pairId: this.pair?.id,
        processId: processId,
        gseId: this.pairProcesses[index].gseId,
        amount: this.pairProcesses[index].amount,
        id: this.pairProcesses[index].id,
        startTime,
      })
    }
)).subscribe((result) => {
      element.disabled = false;
      element.classList.remove('btn-disabled');
        this.pairProcessesService.pendingRequests.delete(processId);
        if (!result || !Object.keys(result)?.length) {
          return;
        }
        this.processDetailsArray[index].startedAt = startTime;
        this.processDetailsArray[index].secondsCount = dayjs.utc().diff(this.processDetailsArray[index].startedAt, "seconds");
      this.toastService.showSuccess('Process ' + this.processDetailsArray?.[index]?.processName + ' has started');
    }));
  }

  async finishTime(processId: number, event: MouseEvent) {
    const index = this.processDetailsArray.findIndex((processDetail) => processDetail.processId === processId);
    const element = event.target as HTMLButtonElement;
    element.disabled = true;
    const endTime = dayjs.utc().toDate();
    this.pairProcessesService.savePairProcess({
        pairId: this.pair?.id,
        processId: processId,
        gseId: this.pairProcesses[index].gseId,
        amount: this.pairProcesses[index].amount,
        id: this.pairProcesses[index].id,
        endTime,
      }).subscribe((result) => {
      element.disabled = false;
        if (!result || !Object.keys(result)?.length) {
          return;
        }
      this.toastService.showSuccess('Process ' + this.processDetailsArray?.[index]?.processName + ' has finished');
      this.processDetailsArray[index].finishedAt = endTime;
      this.processDetailsArray[index].secondsCount = dayjs.utc(this.processDetailsArray[index].finishedAt).diff(this.processDetailsArray[index].startedAt, "seconds");
    });
  }

  async resumeTime(processId: number, event: MouseEvent) {
    const index = this.processDetailsArray.findIndex((processDetail) => processDetail.processId === processId)
    const element = event.target as HTMLButtonElement;
    element.disabled = true;

    const resumeTime = dayjs.utc();
    this.pairProcessesService.savePairProcess({
        pairId: this.pair?.id,
        processId: processId,
        gseId: this.pairProcesses[index].gseId,
        amount: this.pairProcesses[index].amount,
        id: this.pairProcesses[index].id,
        endTime: null,
      }).subscribe((result) => {
      element.disabled = false;
      if(!result || !Object.keys(result)?.length) {
        return;
      }
      this.toastService.showSuccess('Process ' + this.processDetailsArray?.[index]?.processName + ' has been resumed');
      this.processDetailsArray[index].finishedAt = null;
      this.processDetailsArray[index].secondsCount = resumeTime.diff(this.processDetailsArray[index].startedAt, "seconds");
    });
  }

  openViewProcesses() {
    if (this.pageAccess !== Access.RW) {
      return;
    }
    const modalRef = this.modalService.open(RampViewProcessesComponent, {size: 'lg', centered: true});
    const modal: RampViewProcessesComponent = modalRef.componentInstance;
    modal.gses = this.gses;
    modal.processes = this.processes;
    modal.pairProcesses = this.pairProcesses;
    modal.pairId = this.pair?.id;
    modal.user = this.user;
    modal.acTypeId = this.acTypeId;
    modal.airportId = this.airportId;
    modalRef.dismissed.subscribe(() =>{
      this.processDetailsArray = [];
      this.gses = [];
      forkJoin([
        this.pairProcessesService.getPairProcesses({pairId: this.pair?.id, isActive: true}),
        this.processService.getProcesses({isActive: true}),
        this.gseService.fetchGses({ isActive: true, isNone: false}),
      ]).subscribe((results) => {
        this.pairProcesses = results[0];
        this.processes = results[1];
        for (const process of this.processes) {
          const filteredGse = results[2].find((gse) => gse.acTypeId === this.acTypeId && gse.airportId === this.airportId && gse.processId === process.id);
          if (filteredGse) {
            this.gses.push(filteredGse);
            continue;
          }
          this.gses.push(results[2].find((gse) => gse.processId === process.id))
        }
        this.createProcessDetailsArray();
      });
    })

  }

  public readonly Access = Access;
}
