<div class="modal-header">
  <div class="modal-title">Edit Loading Instruction</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')"></button>
</div>
<div class="modal-body dark-blue">
  <div class="d-flex flex-column gap-3">
    <div class="d-flex justify-content-between">
      <div>Compartment</div>
      <input class="form-control light-blue-border" type="number" [(ngModel)]="compartment">
    </div>
    <div class="divider"></div>
    <div class="d-flex justify-content-between">
      <div>Position</div>
      <input class="form-control light-blue-border" type="number" [(ngModel)]="position">
    </div>
    <div class="divider"></div>
    <div class="d-flex justify-content-between">
      <div>Weight</div>
      <input class="form-control light-blue-border" type="number" [(ngModel)]="weight">
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex gap-2 mt-2 justify-content-end col-12">
    <button class="btn flex-basis-25 cancel-btn" (click)="closeModal('')">Cancel</button>
    <button class="btn button-blue flex-basis-25" (click)="saveForm()">Save</button>
  </div>
</div>
