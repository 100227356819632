import {IGenericContainerObject} from '../models/genericContainerObject.model';
import { IGse } from '../models/gse.model';
import { Equipment } from './equipment.constants';

export class DefinedGses {
  static DEFAULT: IGse = {
    title: 'Truck',
    equipmentIdentifier: Equipment.DEFAULT,
    class: 'icon-icons8-truck'
  }; // 'DEFAULT';

  static STAIRS: IGse = {
    title: 'Stairs',
    equipmentIdentifier: Equipment.STAIRS,
    class: 'icon-stairs'
  }; // 'STAIRS';

  static CLEANING: IGse = {
    title: 'Cleaning service',
    equipmentIdentifier: Equipment.CLEANING,
    class: 'icon-cleaning-service-worker'
  }; // 'CLEANING';

  static CATERING: IGse = {
    title: 'Catering Service',
    equipmentIdentifier: Equipment.CATERING,
    class: 'icon-icons8-tableware fs-38 gse-color'
  }; // 'CATERING';

  static FUELING: IGse = {
    title: 'Fueling Service',
    equipmentIdentifier: Equipment.FUELING,
    class: 'icon-fuel-service'
  }; // 'FUELING';

  // static CONVEYOR: IGse = {
  //   title: 'Conveyor',
  //   equipmentIdentifier: Equipment.CONVEYOR,
  //   class: 'icon-icons8-escalator-up'
  // }; // 'CONVEYOR';

  static LUGGAGE_CART: IGse = {
    title: 'Luggage Cart',
    equipmentIdentifier: Equipment.LUGGAGE_CART,
    class: 'icon-baggage-truck'
  }; // 'LUGGAGE_CART';

  static HIGH_LOADER: IGse = {
    title: 'High Loader',
    equipmentIdentifier: Equipment.HIGH_LOADER,
    class: 'icon-highloader'
  }; // 'SCICCOR_LIFT';

  static BUS: IGse = {
    title: 'Bus',
    equipmentIdentifier: Equipment.BUS,
    class: 'icon-bus'
  }; // 'BUS';

  static FIRE_TRUCK: IGse = {
    title: 'Fire truck',
    equipmentIdentifier: Equipment.FIRE_TRUCK,
    class: 'icon-firetruck'
  }; // 'FIRE_TRUCK';

  static DEICING: IGse = {
    title: 'De-icing Service',
    equipmentIdentifier: Equipment.DEICING,
    class: 'icon-deicing'
  }; // 'DEICING';

  static JETWAY: IGse = {
    title: 'Jetway',
    equipmentIdentifier: Equipment.JETWAY,
    class: 'icon-jetway'
  }; // 'JETWAY';

  static WC: IGse = {
    title: 'Water Service',
    equipmentIdentifier: Equipment.WC,
    class: 'icon-wc'
  }; // 'WATER_SERVICE';

  static ASU: IGse = {
    title: 'Aircraft Starter Unit',
    equipmentIdentifier: Equipment.ASU,
    class: 'icon-plane-asu'
  }; // 'ASU';

  static GPU: IGse = {
    title: 'Ground Power Unit',
    equipmentIdentifier: Equipment.GPU,
    class: 'icon-plane-gpu'
  }; // 'GPU';

  static MARSHALING: IGse = {
    title: 'Marshaling',
    equipmentIdentifier: Equipment.MARSHALING,
    class: 'icon-marshaling',
  }; // 'MARSHALING';

  static TOWING: IGse = {
    title: 'Towing',
    equipmentIdentifier: Equipment.TOWING,
    class: 'icon-towing'
  }; // 'TOWING';

  static AMBULANCE: IGse = {
    title: 'Ambulance',
    equipmentIdentifier: Equipment.AMBULANCE,
    class: 'icon-ambulance'
  }; // 'AMBULANCE';

  static TOWER: IGse = {
    title: 'Tower',
    equipmentIdentifier: Equipment.TOWER,
    class: 'icon-tower'
  }; // 'TOWER';


  static NONE: IGse = {
    title: 'None',
    equipmentIdentifier: null,
    class: ''
  };

  static LIST = [
    DefinedGses.STAIRS,
    DefinedGses.CLEANING,
    DefinedGses.CATERING,
    DefinedGses.FUELING,
    // DefinedGses.CONVEYOR,
    DefinedGses.LUGGAGE_CART,
    DefinedGses.HIGH_LOADER,
    DefinedGses.BUS,
    DefinedGses.FIRE_TRUCK,
    DefinedGses.DEICING,
    DefinedGses.JETWAY,
    DefinedGses.WC,
    DefinedGses.ASU,
    DefinedGses.GPU,
    DefinedGses.MARSHALING,
    DefinedGses.TOWING,
    DefinedGses.AMBULANCE,
    DefinedGses.TOWER,
  ];

  static IGENERIC_CONTAINER_OBJECT: IGenericContainerObject<IGse> = {
    STAIRS: DefinedGses.STAIRS,
    CLEANING_SERVICE: DefinedGses.CLEANING,
    CATERING: DefinedGses.CATERING,
    FUELING: DefinedGses.FUELING,
    // CONVEYOR: DefinedGses.CONVEYOR,
    LUGGAGE_CART: DefinedGses.LUGGAGE_CART,
    HIGH_LOADER: DefinedGses.HIGH_LOADER,
    BUS: DefinedGses.BUS,
    FIRE_TRUCK: DefinedGses.FIRE_TRUCK,
    DEICING: DefinedGses.DEICING,
    JETWAY: DefinedGses.JETWAY,
    WC: DefinedGses.WC,
    ASU: DefinedGses.ASU,
    GPU: DefinedGses.GPU,
    MARSHALING: DefinedGses.MARSHALING,
    TOWING: DefinedGses.TOWING,
    AMBULANCE: DefinedGses.AMBULANCE,
    TOWER: DefinedGses.TOWER,
  };
}
