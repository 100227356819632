<div class="header-container">
  <div class="d-flex gap-1">
<!--    <div class="icon-back"></div>-->
    <div class="icon-container" *ngIf="activeComponent?.canGoBack" (click)="goBack()" data-test="header-back-button">
      <img src="assets/icons/arrow-left.svg" width="10" alt="Back" />
    </div>
    <div class="headline" data-test="page-title">{{ activeComponent?.pageTitle }}</div>
  </div>
  <div class="d-flex gap-3">
    <div class="time" data-test="time">{{time | date:'HH:mm':'+0000'}}z</div>
    <app-user-photo [user]="user" [size]="35" circleBackground="#F2F2F2" textColor="#002060" (click)="openUserCard()" data-test="user-photo"></app-user-photo>
  </div>
</div>
