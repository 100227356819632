import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PermissionService} from "../../services/permission.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  @Input() user: any;

  form: FormGroup;
  isBusy = false;
  destroy$ = new Subject();
  errorText?: string;

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private userService: UserService, private route: ActivatedRoute,
              private router: Router, private permissionService: PermissionService) {
    this.auth.user.pipe(takeUntil(this.destroy$)).subscribe(async(user) => {
      if ((user?.multiFactor as any)?.user) {
        while(!permissionService.permissionsLoaded) {
          await new Promise((res) => setTimeout(res, 100));
        }
        const mobilePermissions = permissionService.permissions.filter((perm) => perm.uiMask.startsWith('MOBILE'));
        if (!mobilePermissions?.length) {
          this.auth.signOut();
          permissionService.permissionsLoaded = false;
          permissionService.permissionApplications = [];
          permissionService.permissions = [];
          this.errorText = 'You are not allowed to access avitium RampWatch mobile application. Please contact System Admin.';
          this.isBusy = false;
          return;
        }
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      }
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy() {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  createForm() {
    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  signIn() {
    if (this.form.invalid) {
      return;
    }
    this.isBusy = true;
    const formVal = this.form.value;

    this.auth.signInWithEmailAndPassword(formVal.username, formVal.password).then(item => {
      // console.log('sign in res:', item);

      if (item.user && item.user.uid) {
        this.errorText = undefined;
        this.userService.setUserByUid(item.user.uid);
      }

    }).catch(error => {
      console.log('sign in error:', error);
      this.errorText = 'You are not allowed to proceed because either the given credentials are incorrect or you do not have the permission!';
      this.isBusy = false;
    });
  }

}
