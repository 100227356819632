import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, Observable, of, retryWhen, Subscription, throwError, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { IPairsProcessesModel } from '../shared/models/pair-processes.model';

@Injectable({
  providedIn: 'root'
})
export class PairProcessesService {
  // Key: Pair Process ID
  pendingRequests: Map<number, Subscription> = new Map<number, Subscription>();

  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  abortPairProcessSaving(pairProcessId: number) {
    if(this.pendingRequests.has(pairProcessId)) {
      this.pendingRequests.get(pairProcessId).unsubscribe();
      this.pendingRequests.delete(pairProcessId);
    }
  }

  getPairProcesses(filter: Partial<IPairsProcessesModel> = { isActive: true }): Observable<IPairsProcessesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pairs-processes/search`, filter, {}).pipe(
      map((item: IResponse<IPairsProcessesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

  savePairProcess(pairProcess: IPairsProcessesModel): Observable<IPairsProcessesModel> {
    let obs;
    if (pairProcess.id) {
      obs = this.httpClient.patch(`${environment.api_base_url}pairs-processes/${pairProcess.id}`, pairProcess, {})
    } else {
      obs = this.httpClient.post(`${environment.api_base_url}pairs-processes/create`, pairProcess, {})
    }
    return obs.pipe(
      map((item: IResponse<IPairsProcessesModel>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((err, i) => {
            const retryAttempt = i + 1;
            if (retryAttempt > 3) {
              // Maximum number of retries reached
              return throwError(err);
            }
            this.toastService.showError(`Turnaround process not saved due to network issues, retrying (${retryAttempt}/3)`);

            return timer(3000);
          }),
        )
      ),
      catchError((error) => {
        this.toastService.showError("Turnaround process not saved due to network issues");
        console.error(error);
        return of({});
      })
    );
  }
}
