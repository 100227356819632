import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, Observable, of, retryWhen, Subscription, throwError, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { ToastService } from './toast.service';
import { IPairsProcessesModel } from '../shared/models/pair-processes.model';
import { IPairTurnaroundStatesModel } from '../shared/models/pair-turnaround-states.model';

@Injectable({
  providedIn: 'root'
})
export class PairTurnaroundStatesService {
 
  constructor(private httpClient: HttpClient, private toastService: ToastService) { }

  getPairTurnaroundState(filter: Partial<IPairTurnaroundStatesModel> = { isActive: true }): Observable<IPairTurnaroundStatesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/search`, filter, {}).pipe(
      map((item: IResponse<IPairTurnaroundStatesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }

}
