import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PermissionService} from "../../../../services/permission.service";
import {Access, PermissionUIMasks} from "../../../../shared/enums/permissions.enums";

@Component({
  selector: 'app-start-turnaround',
  templateUrl: './start-turnaround.component.html',
  styleUrls: ['./start-turnaround.component.scss']
})
export class StartTurnaroundComponent implements OnInit {

  @Output() startClicked = new EventEmitter();
  @Input() disabled = false;
  startAccess: Access;
  constructor(private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.permissionService.getPermissionAccess(PermissionUIMasks.MOBILE_START_TURNAROUND).then((access) => {
      this.startAccess = access;
    });
  }

  public readonly Access = Access;
}
