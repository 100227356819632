<form [formGroup]="form">
  <div class="extend-time-container">
    <div class="text-container roboto-font dark-blue fw-bold">
      Extend Time:
    </div>
    <div class="form-field-container">
      <input type="number" formControlName="time"/>
    </div>
    <button class="btn button-blue roboto-font d-flex justify-content-center" [disabled]="!form || !form.get('time') || form.get('time').invalid" (click)="onExtendClicked()" data-test="request-extension-btn"><span class="align-self-center">Request</span></button>
  </div>
</form>

