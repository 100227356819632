// Flight number departing
// Flight number arriving
// pair key
import { IPairDetail } from './pair-detail.model';

export interface IPairOverview {
  id?: number;
  arrivalLegId?: number;
  departureLegId?: number;
  dateOfOperation?: Date;
  acRegistration?: string;

  ctot?: Date;
  turnaroundTime?: number;
  turnaroundInProgress?: boolean;
  groundTime?: number;

  std?: Date;
  sta?: Date;
  etd?: Date;
  eta?: Date;
  atd?: Date;
  ata?: Date;

  hasMessage?: boolean;
  hasMemo?: boolean;
  hasAlerts?: boolean;
  ctotCancelled?: boolean;


  locked?: any;
  flightNumberDeparting?: string;
  flightNumberArriving?: string;

  airlineDesignator?: string;
  status?: string;

  // DOES NOT EXIST YET
  pairKey?: string;

  hasExtensionRequest?: boolean;
  arrivalAircraftStand?: string;
  landingTime?: Date;

  arrivalLegArrivalStation?: string;
  arrivalLegDepartureStation?: string;

  departureLegArrivalStation?: string;
  departureLegDepartureStation?: string;

  arrivalLegStd?: Date;
  departureLegStd?: Date;
  arrivalLegAta?: Date;
  departureLegAtd?: Date;
}

export function isPairOverview(pair: IPairOverview | IPairDetail): pair is IPairOverview {
  return 'hasExtensionRequest' in pair;
}
