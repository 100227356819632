
<div class="position-relative">
  <app-access-denied *ngIf="!pageAccess"></app-access-denied>
  <div class="flight-info-headline-container pb-2 roboto-font dark-blue ms-1 me-1">
    <span class="fw-bold">Turnaround Processes</span>
    <span class="filter-container" [ngClass]="{ disabled: pageAccess !== Access.RW }" (click)="openViewProcesses()">Edit Processes</span>
  </div>
  <div class="mt-2 process-info-data-container" *ngFor="let process of processDetailsArray; let i = index">
    <div class="flex-grow-1 dark-blue process-name">{{process.processName}}</div>
    <div class="time-container dark-blue">
      <div id="timer-{{i}}" class="p-1">{{process.secondsCount | timeFromSeconds}}</div>
    </div>
    <div class="button-container">
      <button #startBtn *ngIf="process.startedAt === null" [disabled]="turnaroundState !== 'IN_PROGRESS' && turnaroundState !== 'RESUMED' || pageAccess !== Access.RW" type="button" class="btn col-12 control-btn" [ngClass]="{ 'btn-start': !pair?.departureLeg?.atd, 'btn-secondary': pair?.departureLeg?.atd, 'disabled': pageAccess !== Access.RW }" (click)="startTime(process.processId, $event)">
        <img class="btn-icon" src="content/images/icons/start-process-button-icon.png" alt="start icon"/>
        <div class="btn-text">{{hasPendingRequest(process.processId) && !startBtn.classList.contains('btn-disabled') ? 'Abort' : 'Start'}}</div>
        <div class="progress-bar"></div>
      </button>
      <button *ngIf="process.finishedAt !== null" [disabled]="turnaroundState !== 'IN_PROGRESS' && turnaroundState !== 'RESUMED' || pageAccess !== Access.RW" type="button" class="btn col-12 control-btn" [ngClass]="{ 'btn-resume': !pair?.departureLeg?.atd || !pair, 'btn-secondary': pair?.departureLeg?.atd, 'disabled': pageAccess !== Access.RW }" (click)="resumeTime(process.processId, $event)">
        <img class="btn-icon" src="content/images/icons/resume-process-button-icon.png" alt="resume icon"/>
        <div class="btn-text">Resume</div>
        <div class="progress-bar"></div>
      </button>
      <button *ngIf="process.startedAt !== null && process.finishedAt === null" [disabled]="turnaroundState !== 'IN_PROGRESS' && turnaroundState !== 'RESUMED' || pageAccess !== Access.RW" type="button" class="btn col-12 control-btn button-with-progress" [ngClass]="{ 'btn-finish': !pair?.departureLeg?.atd || !pair, 'btn-secondary': pair?.departureLeg?.atd, 'disabled': pageAccess !== Access.RW  }" (click)="finishTime(process.processId, $event)">
        <div class="icon-completed-flights-white btn-icon"></div>
        <div class="btn-text">Finish</div>
        <div class="progress-bar"></div>
      </button>
    </div>
  </div>

</div>
