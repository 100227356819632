<div class="bg-white h-100 roboto-font dark-blue">
  <ng-container *ngIf="!groupSelection">
  <div class="row custom-bottom-border ms-1 me-1 mt-4">
    <div class="col-12 p-2 d-flex gap-2 mb-3">
      <app-search-bar class="flex-grow-1" (onSearch)="onConversationSearch($event)"></app-search-bar>
      <img class="align-self-center new-icon" src="content/images/icons/pencil-square.svg" width="20" height="20" [ngbPopover]="popContent" popoverClass="chat-search-popover-size avitium-scrollbar"  placement="bottom-right" autoClose="outside" #popover="ngbPopover" data-test="new-chat-button">
      <ng-template #popContent>
        <form class="flex flex-column popover-height roboto-font dark-blue" [formGroup]="formGroup">
          <div class="input-group has-search search-box w-100 mb-1">
            <img src="content/images/icons/search.svg" width="26" height="26" class="form-control-feedback" alt="search">
            <input type="text" class="form-control popover-search" placeholder="Search" formControlName="searchText">
          </div>
          <div class="chat-contact-list">
            <app-simple-chat-contact *ngFor="let user of popoverUsers" [user]="user" (click)="createChatGroup(user); popover.close();" [attr.data-test]="'user-' + user.firstname + '_' + user.lastname"></app-simple-chat-contact>
          </div>
        </form>
      </ng-template>
    </div>
  </div>
  <div class="row ms-1 me-1">
    <div class="col-12 p-0 overflow-scroll" id="msgGroupList">
      <app-message-group-list [searchKeyword]="searchKeyword" (groupSelectionAction)="groupSelection = $event"></app-message-group-list>
    </div>
  </div>
  </ng-container>

  <ng-container *ngIf="groupSelection">
    <app-message-group-detail [messageGroup]="groupSelection" (exitDetailView)="groupSelection = null"></app-message-group-detail>
  </ng-container>
</div>
