<div class="page-container">
  <div class="list-container mt-3">
    <div class="row filter-container">
      <div style="text-align: right;" class="header-funnel me-2" (click)="openFilterModal()" data-test="open-filters">
        Filter
      </div>
    </div>
    <app-turnarounds-pair-list [pairList]="pairList" [isFirestore]="true"></app-turnarounds-pair-list>
  </div>
</div>
