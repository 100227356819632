import {Component, OnInit} from '@angular/core';
import {firstValueFrom, Subject, takeWhile} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {FilterCategory} from '../../shared/models/filter.model';
import {IPairOverview} from '../../shared/models/pair-overview.model';
import {UserService} from '../../services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FilterService} from '../../services/filter.service';
import {AcTypesService} from '../../services/ac-types.service';
import {AcRegistrationsService} from '../../services/ac-registrations.service';
import {PairService} from '../../services/pair.service';
import {take} from 'rxjs/operators';
import {RoutableModel} from '../../shared/models/routable.model';
import {FilterDialogComponent} from '../../shared/components/filter-dialog/filter-dialog.component';
import {UserLegAssignmentService} from "../../services/user-leg-assignment.service";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";
import {PermissionService} from "../../services/permission.service";

@Component({
  selector: 'app-completed-flights',
  templateUrl: './completed-flights.component.html',
  styleUrls: ['./completed-flights.component.scss']
})
export class CompletedFlightsComponent implements OnInit, RoutableModel {

  backUrl: string = '/home';
  canGoBack: boolean = true;
  pageTitle: string = 'Completed Flights'

  unsubscribe$ = new Subject();
  pairList: IPairOverview[] = [];
  page = 1;
  hasNextPage = false;
  isBusy = false;
  legAssignedIds: number[];

  constructor(private userService: UserService, private modalService: NgbModal, private fb: FormBuilder, private filterService: FilterService, private acTypeService: AcTypesService, private acRegistrationService: AcRegistrationsService, private pairService: PairService, private userLegAssignmentService: UserLegAssignmentService, private permissionService: PermissionService) {
    filterService.filterDataSubject.value.completedOnly = true;
    filterService.filterDataSubject.value.timespan = undefined;
    this.userService.userSubject.pipe(takeWhile((user) => !user, true)).subscribe((user) => {
      if (user) {
        if (user.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
          this.userLegAssignmentService.getMyAssignedLegs().subscribe((res) => {
            this.legAssignedIds = res.map((data) => data.arrivalLegId);
            this.refetchPairs();
          });
          return;
        }
        this.refetchPairs();
      }
    });
  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  openFilterModal() {
    const modal = this.modalService.open(FilterDialogComponent, {centered: true, ariaLabelledBy: 'modal-basic-title'});
    const modalRef = modal.componentInstance as FilterDialogComponent;
    modalRef.completedOnly = true;
    modal.closed.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.searchClicked();
      }
    });
    modalRef.filterCategory = FilterCategory.COMPLETED_FLIGHTS;
  }

  searchClicked() {
    this.page = 1;
    this.refetchPairs();
  }

  nextPage() {
    if (!this.hasNextPage) {
      return;
    }
    this.page++;
    this.refetchPairs();
  }

  previousPage() {
    if (this.page === 1) {
      return;
    }
    this.hasNextPage = true;
    this.page--;
    this.refetchPairs();
  }

  async refetchPairs() {
    this.isBusy = true;
    this.pairList = [];
    this.pairList = await firstValueFrom(this.pairService.getPairsOverview(this.page)).catch(() => []);
    if (this.userService.userSubject?.value?.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && this.legAssignedIds !== undefined && this.legAssignedIds.length) {
      this.pairList = this.pairList.filter((pair) => this.legAssignedIds.includes(pair.arrivalLegId));
    } else if (this.permissionService.airportsThatCanSee?.length && this.permissionService.airportsThatCanSee?.[0]?.id !== 0) {
      const airportIata = this.permissionService.airportsThatCanSee.map((airport) => airport.iata);
      this.pairList = this.pairList.filter((pair) => airportIata.includes(pair?.departureLegDepartureStation))
    }
    this.hasNextPage = this.pairList?.length === 21;
    this.pairList = this.pairList.slice(0, 20);
    this.isBusy = false;
  }
}
