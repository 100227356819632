<ngb-alert [dismissible]="false" *ngIf="noUsersAlert">
  No users found to chat for this station.
</ngb-alert>
<div *ngIf="pair" class="list-container mt-3">
  <div class="row filter-container">
    <div class="w-auto" (click)="openViewMemo()" data-test="open-memos">
      Memo
    </div>
    <div class="w-auto" (click)="openViewPairChat()" data-test="open-chat">
      Chat
    </div>
  </div>
  <div class="ms-2 mt-3 mb-2 dateContainer roboto-font dark-blue">
    {{pair.dateOfOperation | date: 'dd.MM.yyyy'}}
  </div>
  <div class="my-3 d-flex flex-column gap-3">
    <app-turnarounds-pair-list-item [pair]="pair" [attr.data-test]="pair | pairTestAttribute"></app-turnarounds-pair-list-item>
  </div>
  <div class="turnaround-time-container" *ngIf="pair.turnaround?.currentState === turnaroundStatus.IN_PROGRESS || pair.turnaround?.currentState === turnaroundStatus.RESUMED">
    <app-turnaround-time [groundTimeInMinutes]="groundTime" [lastExtendRequest]="pair?.turnaround?.lastExtendEtdRequest"></app-turnaround-time>
  </div>
  <nav class="navbar navbar-dark flex-down">
      <ul class="list-horizontal text-white float-start roboto-font" data-test="menu-tabs">
        <li [ngClass]="{ active: activeId === 1 }" (click)="activeId = 1">Flight Info</li>
        <li [ngClass]="{ active: activeId === 2 }" (click)="activeId = 2">Processes</li>
        <li [ngClass]="{ active: activeId === 3 }" (click)="activeId = 3">Loading Info</li>
        <li [ngClass]="{ active: activeId === 4 }" (click)="activeId = 4">Times</li>
      </ul>
  </nav>
  <div class="c-tab-content">
    <div *ngIf="activeId === 4">
      <app-enter-times [pair]="pair" [pageAccess]="timesAccess" *ngIf="timesAccess !== undefined"></app-enter-times>
    </div>
    <div *ngIf="activeId === 3">
      <app-loading-info [pageAccess]="loadingInfoAccess"></app-loading-info>
    </div>
    <div *ngIf="activeId === 2">
      <app-pair-processes [pageAccess]="processesInfoAccess" [airportId]="pair.departureLeg.departureStationId" [acTypeId]="pair.departureLeg.acTypeId" [user]="user" [pair]="pair"></app-pair-processes>
    </div>
    <div *ngIf="activeId === 1">
      <div class="position-relative flex flex-column">
        <app-access-denied *ngIf="!flightInfoAccess"></app-access-denied>
        <app-pair-detail-leg [leg]="pair.arrivalLeg" [isArrivalLeg]="true" data-test="arrival-leg"></app-pair-detail-leg>
        <app-pair-detail-leg [leg]="pair.departureLeg" [isArrivalLeg]="false" data-test="departure-leg"></app-pair-detail-leg>
      </div>

      <ng-container *ngIf="pair?.turnaround && pair.turnaround.currentState !== turnaroundStatus.UPCOMING">
        <div class="remaining-time-container mt-3">
          <app-remaining-time [turnaroundData]="pair?.turnaround" [tod]="pair?.departureLeg?.tod"></app-remaining-time>
        </div>
        <div *ngIf="pair?.turnaround?.currentState !== turnaroundStatus.FINISHED" class="extend-time-container mt-3">
          <app-extend-time #extendTime [form]="form" (extendClicked)="openExtendForm(content)"></app-extend-time>
        </div>
        <div class="turnaround-controls-container">
          <app-turnaround-controls
            [disabled]="isBusy || form?.value?.time !== null && form?.value?.time != 0"
            [turnaround]="pair?.turnaround"
            (pauseClicked)="pauseTurnaround()"
            (resumeClicked)="resumeTurnaround()"
            (finishClicked)="finishTurnaround()"
          ></app-turnaround-controls>
        </div>
      </ng-container>
    </div>
    <div *ngIf="isFirestore && !pair?.departureLeg?.atd">
      <div *ngIf="!pair?.turnaround || pair.turnaround.currentState === turnaroundStatus.UPCOMING" class="start-container">
        <app-start-turnaround [disabled]="isBusy" (startClicked)="startTurnaround()"></app-start-turnaround>
        <ng-container *ngIf="pair?.turnaround && pair.turnaround.currentState !== turnaroundStatus.UPCOMING">
          <div class="remaining-time-container border-bottom-gray bg-white">
            <app-remaining-time [turnaroundData]="pair?.turnaround" [tod]="pair?.departureLeg?.tod"></app-remaining-time>
          </div>
          <div *ngIf="pair?.turnaround?.currentState !== turnaroundStatus.FINISHED" class="extend-time-container border-bottom-gray bg-white">
            <app-extend-time [form]="form" (extendClicked)="openExtendForm(content)"></app-extend-time>
          </div>
          <div class="turnaround-controls-container">
            <app-turnaround-controls
              [disabled]="isBusy"
              [turnaround]="pair?.turnaround"
              (pauseClicked)="pauseTurnaround()"
              (resumeClicked)="resumeTurnaround()"
              (finishClicked)="finishTurnaround()"
            ></app-turnaround-controls>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<ng-template #content let-modal>
  <div class="modal-header poppins dark-blue">
    <div class="modal-title">Request turnaround time extension</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" data-test="close-extension-modal"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="reasonForExtension" class="roboto-font dark-blue">Reason for extension*</label>
        <div class="input-group">
          <textarea id="reasonForExtension" formControlName="reason" rows="4" ngbAutofocus class="roboto-font dark-blue" placeholder="Extension reason" data-test="extension-reason"></textarea>
        </div>
        <div class="button-container align-self-end col-auto">
          <button type="button" class="btn button-blue" [disabled]="form?.invalid" (click)="modal.close('Save click')" data-test="submit-extension-request-btn">Submit request</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
