import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {IResponse} from '../shared/models/IResponse.model';
import {IAirportContact} from '../shared/models/airport-contact.model';
import {environment} from '../../environments/environment';
import {IAirport} from '../shared/models/airport.model';
import {Searchable} from '../shared/utils/searchable.type';


@Injectable({
  providedIn: 'root'
})
export class AirportsService {



  constructor(private httpClient: HttpClient) {
    this.fetchAirports().subscribe(() => {});
  }

  fetchAirports(filter: Searchable<IAirport> = { isActive: true }): Observable<IAirport[]> {
    return this.httpClient.post(`${environment.api_base_url}airports/search`, filter, {}).pipe(
      map((item: IResponse<IAirport[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }

  fetchAirportContactCompanies(airportId?: number): Observable<IAirportContact[]> {
    return this.httpClient.post(`${environment.api_base_url}airport-contact-companies/search`, { isActive: true, airportId: airportId }, {}).pipe(
      map((item: IResponse<IAirportContact[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }
}
