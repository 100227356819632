<ng-container *ngFor="let pair of pairList, let i = index; trackBy: pairTrackBy">
  <div *ngIf="i === 0 || datesNotSameDay(pair.dateOfOperation, pairList[i-1].dateOfOperation)" class="ms-2 mt-3 mb-2 dateContainer">
    {{pair.dateOfOperation | date: 'dd.MM.yyyy'}}
  </div>
  <div class="my-3 d-flex flex-column gap-3">
    <app-turnarounds-pair-list-item [pair]="pair" [routerLink]="(isFirestore ? '/turnarounds/' : '/flight/') + pair.id" [attr.data-test]="pair | pairTestAttribute"></app-turnarounds-pair-list-item>
  </div>
</ng-container>
<ng-container *ngIf="!pairList?.length && !isBusy">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-8 text-center roboto-font dark-blue mt-3">
        No pairs found!
      </div>
    </div>
  </div>

</ng-container>
