import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeWhile } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterCategory } from '../../shared/models/filter.model';
import { FilterService } from '../../services/filter.service';
import { UserService } from '../../services/user.service';
import { RoutableModel } from '../../shared/models/routable.model';
import { PairService } from '../../services/pair.service';
import { IPairDetail } from '../../shared/models/pair-detail.model';
import * as dayjs from 'dayjs';
import { FilterDialogComponent } from '../../shared/components/filter-dialog/filter-dialog.component';
import {UserLegAssignmentService} from "../../services/user-leg-assignment.service";
import {StaticUserGroupConstants} from "../../shared/constants/static-user-group.constants";
import {PermissionService} from "../../services/permission.service";

@Component({
  selector: 'app-turnarounds-page',
  templateUrl: './turnarounds-page.component.html',
  styleUrls: ['./turnarounds-page.component.scss']
})
export class TurnaroundsPageComponent implements OnInit, OnDestroy, RoutableModel {
  backUrl: string = '/home';
  canGoBack: boolean = true;
  pageTitle: string = 'Day of Operation';
  pairList: IPairDetail[] = [];
  legsAssignedIds: number[];

  unsubscribe$ = new Subject();

  firestorePairsSub: Subscription;

  constructor(private userService: UserService, private modalService: NgbModal, private filterService: FilterService, private pairService: PairService, private userLegAssignmentService: UserLegAssignmentService, private permissionService: PermissionService) {
    this.filterService.filterDataSubject.value.dateFrom = undefined;
    this.filterService.filterDataSubject.value.dateTo = undefined;
  }

  ngOnInit(): void {
    this.filterService.filterDataSubject.value.completedOnly = false;
    this.userService.userSubject.pipe(takeWhile((user) => !user, true)).subscribe((user) => {
      if (user?.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
        this.userLegAssignmentService.getMyAssignedLegs().subscribe((result) => {
          this.legsAssignedIds = result.map((data) => data.arrivalLegId);
          this.getPairs();
        });
      } else if (user) {
        this.getPairs();
      }
    });
  }

  getPairs(): void {
    if(this.firestorePairsSub) {
      this.firestorePairsSub.unsubscribe();
    }
    this.firestorePairsSub = this.pairService.getPairsFirestore(false, null).pipe(takeUntil(this.unsubscribe$)).subscribe(async (keyList: IPairDetail[]) => {
      if (this.userService.userSubject.value.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT && this.legsAssignedIds !== undefined && this.legsAssignedIds.length) {
        keyList = keyList.filter((pair) => this.legsAssignedIds.includes(pair.arrivalLeg?.id));
      } else if (this.permissionService.airportsThatCanSee?.length && this.permissionService.airportsThatCanSee?.[0]?.id !== 0) {
          const airportIds = this.permissionService.airportsThatCanSee.map((airport) => airport.id);
          keyList = keyList.filter((pair) => airportIds.includes(pair.departureLeg?.departureStationId));
      }
      this.pairList = keyList.sort((itemA, itemB) => dayjs(itemA.dateOfOperation).isAfter(itemB.dateOfOperation, 'date') ? 1 : -1);
    });
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  openFilterModal() {
    const modal = this.modalService.open(FilterDialogComponent, {centered: true, ariaLabelledBy: 'modal-basic-title'});
    const modalRef = modal.componentInstance as FilterDialogComponent;
    modalRef.completedOnly = false;
    modal.closed.pipe(take(1)).subscribe((res) => {
      if (res) {
        this.searchClicked();
      }
    });
    modalRef.filterCategory = FilterCategory.DAY_OF_OPERATION;
  }
  searchClicked() {
   this.getPairs();
  }
}
