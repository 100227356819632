import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IGenericContainerObject } from '../../models/genericContainerObject.model';
import { IAcRegistration } from '../../models/ac-registration.model';
import { UserService } from '../../../services/user.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from '../../../services/filter.service';
import { AcTypesService } from '../../../services/ac-types.service';
import { AcRegistrationsService } from '../../../services/ac-registrations.service';
import { PairService } from '../../../services/pair.service';
import { Filter, FilterCategory } from '../../models/filter.model';
import {StaticUserGroupConstants} from "../../constants/static-user-group.constants";

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  form: FormGroup;
  completedOnly?: boolean;
  //acTypes: IGenericContainerObject<IAcType> = {};
  acRegistrations: IGenericContainerObject<IAcRegistration> = {};
  filterCategory: FilterCategory;
  constructor(public modal: NgbActiveModal, private userService: UserService, private modalService: NgbModal, private fb: FormBuilder, private filterService: FilterService, private acTypeService: AcTypesService, private acRegistrationService: AcRegistrationsService, private pairService: PairService) {}

  ngOnInit(): void {
    this.createForm();
    this.getDataForFilter();
  }

  getDataForFilter() {
    this.acRegistrations = this.acRegistrationService.acRegistrations;
        if (this.filterService.filterDataSubject.value?.acRegistration) {
          console.log('Patched acReg:', this.filterService.filterDataSubject.value?.acRegistration);
          this.form.get('acRegistration').setValue(this.filterService.filterDataSubject.value?.acRegistration);
          this.form.get('acRegistration').updateValueAndValidity();
        }
  }


  // get getAvailableAcTypes() {
  //   if (this.form?.value?.acRegistration && this.form?.value?.acRegistration !== 'null') {
  //     return Object.values(this.acTypes).filter((type) => type.id === this.acRegistrations[this.form?.value?.acRegistration?.id].acTypeId);
  //   }
  //   return Object.values(this.acTypes);
  // }

  get getAvailableAcRegistrations() {
    // if (this.form?.value?.acType && this.form?.value?.acType !== 'null') {
    //   return Object.values(this.acRegistrations).filter((registration) => registration.acTypeId === this.form?.value?.acType?.id);
    // }
    return Object.values(this.acRegistrations);
  }

  createForm() {
    this.form = this.fb.group({
      acRegistration: [null],
      acType: [null],
      timespan: [this.filterService.filterDataSubject.value?.timespan || null],
      flightNumber: [this.filterService.filterDataSubject.value?.flightNumber || null],
      passengerGate: [this.filterService.filterDataSubject.value?.passengerGate || null],
      aircraftStand: [this.filterService.filterDataSubject.value?.aircraftStand || null],
      dateFrom: [this.filterService.filterDataSubject.value?.dateFrom || null],
      dateTo: [this.filterService.filterDataSubject.value?.dateTo || null],
    });
  }

  okClicked() {
    let filtersChanged = false;
    const originalFilters = this.filterService.filterDataSubject.value;
    const filter = new Filter(null);
    filter.acRegistration = this.form.value.acRegistration || null;
    filter.passengerGate = this.form.value.passengerGate || null;
    filter.flightNumber = this.form.value.flightNumber || null;
    filter.aircraftStand = this.form.value.aircraftStand || null;
    filter.timespan = this.form.value.timespan || null;
    filter.dateFrom = this.form.value.dateFrom || null;
    filter.dateTo = this.form.value.dateTo || null;
    filter.completedOnly = this.completedOnly;
    if (this.userService.userSubject.value.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
      filter.station = this.userService.userSubject.value.location;
    }
    for (const key in originalFilters) {
      if ((originalFilters[key] === null || originalFilters[key] === undefined) && (filter[key] === null || filter[key] === undefined)) {
        continue;
      }
      if (originalFilters[key] !== filter[key]) {
        filtersChanged = true;
        break;
      }
    }
    if (filtersChanged) {
      this.filterService.setFilter(filter);
    }
    this.modal.close(filtersChanged);
  }

  public readonly FilterCategory = FilterCategory;
}
