import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RampViewPairChatComponent } from './components/ramp-view-pair-chat/ramp-view-pair-chat.component';
import { IndicatorTextComponent } from './components/indicator-text/indicator-text.component';
import { AddEditModalComponent } from './components/add-edit-modal/add-edit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LegKeyInterpreterPipe } from './pipes/leg-key-interpreter.pipe';
import { IndicatorDotComponent } from './components/indicator-dot/indicator-dot.component';
import { ViewModalComponent } from './components/view-modal/view-modal.component';
import { PairListItemComponent } from './components/pair-list-item/pair-list-item.component';
import { ChatSessionMessageComponent } from './components/chat-session-message/chat-session-message.component';
import { ChatSessionFormComponent } from './components/chat-session-form/chat-session-form.component';
import { MinutesToTimePipe } from './pipes/minutes-to-time.pipe';
import { RampViewMemoComponent } from './components/ramp-view-memo/ramp-view-memo.component';
import { IconTextItemSmallComponent } from './components/icon-text-item-small/icon-text-item-small.component';
import { ChatSessionComponent } from './components/chat-session/chat-session.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MinutesFromTimePipe } from './pipes/minutes-from-time.pipe';
import { ChatDialogComponent } from './components/chat-dialog/chat-dialog.component';
import { SelectUserToChatComponent } from './components/select-user-to-chat/select-user-to-chat.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageItemComponent } from './components/chat-session/message-item/message-item.component';
import { EditMessageDialog } from './components/edit-message-dialog/edit-message-dialog.component';
import { UserPhotoComponent } from './components/user-photo/user-photo.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { PairDetailLegComponent } from './components/pair-detail-leg/pair-detail-leg.component';
import { TimeFromMinutesPipe } from './pipes/time-from-minutes.pipe';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { RouterModule } from '@angular/router';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { TabsNavigationComponent } from './components/tabs-navigation/tabs-navigation.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PairProcessesComponent } from './components/pair-processes/pair-processes.component';
import { timeFromSecondsPipe } from './pipes/time-from-seconds.pipe';
import { RampViewProcessesComponent } from './components/ramp-view-processes/ramp-view-processes.component';
import { PairTestAttributePipe } from './pipes/pair-test-attribute.pipe';
import {TimeInputComponent} from "./components/time-input/time-input.component";
import {AccessDeniedComponent} from "./components/access-denied/access-denied.component";


@NgModule({
  declarations: [
    IconTextItemSmallComponent,
    IndicatorDotComponent,
    IndicatorTextComponent,
    MinutesToTimePipe,
    MinutesFromTimePipe,
    timeFromSecondsPipe,
    ViewModalComponent,
    AddEditModalComponent,
    RampViewMemoComponent,
    LegKeyInterpreterPipe,
    RampViewPairChatComponent,
    PairListItemComponent,
    ChatSessionComponent,
    ChatSessionMessageComponent,
    ChatSessionFormComponent,
    ChatDialogComponent,
    SelectUserToChatComponent,
    MessageItemComponent,
    EditMessageDialog,
    UserPhotoComponent,
    ToastsContainerComponent,
    IndicatorDotComponent,
    IndicatorTextComponent,
    LoadingSpinnerComponent,
    PairDetailLegComponent,
    TimeFromMinutesPipe,
    UserMenuComponent,
    FilterDialogComponent,
    PairProcessesComponent,
    RampViewProcessesComponent,
    TabsNavigationComponent,
    PasswordInputComponent,
    PairTestAttributePipe,
    TimeInputComponent,
    AccessDeniedComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        FontAwesomeModule,
        RouterModule
    ],
    exports: [
        IconTextItemSmallComponent,
        MinutesToTimePipe,
        ViewModalComponent,
        RampViewPairChatComponent,
        MinutesFromTimePipe,
        UserPhotoComponent,
        IndicatorDotComponent,
        IndicatorTextComponent,
        LoadingSpinnerComponent,
        PairDetailLegComponent,
        TabsNavigationComponent,
        PasswordInputComponent,
        ToastsContainerComponent,
      PairProcessesComponent,
      timeFromSecondsPipe,
      PairTestAttributePipe,
      TimeFromMinutesPipe,
      TimeInputComponent,
      AccessDeniedComponent
    ],
  providers: [TimeFromMinutesPipe]
})
export class SharedModule { }
