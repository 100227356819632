import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import 'firebase/firestore';
import 'firebase/database';
import * as dayjs from 'dayjs';
import { take, takeUntil, tap } from 'rxjs/operators'
import { IFilter } from '../shared/models/filter.model';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IPairDetail } from '../shared/models/pair-detail.model';
import { FilterService } from './filter.service';
import { environment } from 'src/environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { HttpClient } from '@angular/common/http';
import { IPairOverview } from '../shared/models/pair-overview.model';
import { ngbDateToDayjs } from '../shared/utils/utils';
import { UserService } from './user.service';
import { convertTimestamps } from '../shared/utils/convert-timestamps';
import { Searchable } from '../shared/utils/searchable.type';
import { IPairsModel } from '../shared/models/pairs.model';
import {StaticUserGroupConstants} from "../shared/constants/static-user-group.constants";

@Injectable({
  providedIn: 'root'
})
export class PairService {
  firestoreFlatPairSubject = new Subject();
  firestoreFlatPairSubscription: Subscription;
  firestoreLastSnapshot: firebase.default.firestore.DocumentSnapshot;
  hasNextPage = true;
  pageSize = 30;
  unsubscribe$ = new Subject();

  constructor(private fsDb: AngularFirestore, private rtDb: AngularFireDatabase, private filterService: FilterService, private httpClient: HttpClient, private userService: UserService) {
  }

  getNextFivePairs(): Observable<IPairDetail[]> {
    return this.fsDb.collection<IPairDetail>('pairs', ref => ref.limit(5)).valueChanges();
  }

  getPairPerKey(key: string): Observable<IPairDetail> | null {
    if (!key) {
      return null;
    }
    return this.fsDb.collection(`pairs`).doc(key).valueChanges().pipe(tap((val: any) => {
      convertTimestamps(val);
    }));
  }

  getPairsFirestore(previous: boolean, pairDetail: IPairDetail): Observable<any> {
    this.fetchFirestoreFlatPairs(previous, pairDetail);
    return this.firestoreFlatPairSubject.asObservable();
  }

  private  fetchFirestoreFlatPairs(previous?: boolean, pairDetail?: IPairDetail) {
    if (this.firestoreFlatPairSubscription) {
      this.firestoreFlatPairSubscription.unsubscribe();
    }

    let firestoreObservable = this.fsDb.collection<IPairDetail>('pairs',
      (ref) => this.generateFirestoreQuery(ref, previous, pairDetail)
    ).valueChanges().pipe(map(list => {
      console.table(list);
      if (!list?.length) {
        return [];
      }
      const originalList = [...list];

      const hours = Number(this.filterService.filterDataSubject?.value?.timespan?.match(/\d+/)?.[0]) || 0;

      list = list.filter((pair) => pair.arrivalLeg?.id && pair.departureLeg?.id &&
        (this.filterService.filterDataSubject?.value?.acType ? pair.departureLeg?.acTypeId === this.filterService.filterDataSubject.value.acType?.id : true) &&
        (this.filterService.filterDataSubject?.value?.acRegistration ? pair.departureLeg?.acRegistrationId === this.filterService.filterDataSubject.value.acRegistration?.id : true) &&
        (hours ? dayjs.utc((pair.arrivalLeg.toa as any).seconds, 'X').isBefore(dayjs.utc().add(hours, 'hours')) && dayjs((pair.arrivalLeg.toa as any).seconds, 'X').isAfter(dayjs.utc()) : true) &&
        (this.filterService.filterDataSubject?.value?.flightNumber ? (pair.departureLeg?.flightNumber?.toLowerCase() === this.filterService.filterDataSubject?.value?.flightNumber.toLowerCase() || pair.arrivalLeg?.flightNumber.toLowerCase() === this.filterService.filterDataSubject?.value.flightNumber.toLowerCase()) : true) &&
        (this.filterService.filterDataSubject?.value?.passengerGate ? (pair.departureLeg?.departureGate?.toLowerCase() === this.filterService.filterDataSubject?.value?.passengerGate?.toLowerCase() || pair.departureLeg?.arrivalGate?.toLowerCase() === this.filterService.filterDataSubject?.value?.passengerGate?.toLowerCase() || pair.arrivalLeg?.departureGate?.toLowerCase() === this.filterService.filterDataSubject?.value?.passengerGate?.toLowerCase() || pair.arrivalLeg?.arrivalGate?.toLowerCase() === this.filterService.filterDataSubject?.value?.passengerGate?.toLowerCase()) : true) &&
        (this.filterService.filterDataSubject?.value?.aircraftStand ? (pair.departureLeg?.departureAcStand?.toLowerCase() === this.filterService.filterDataSubject?.value?.aircraftStand?.toLowerCase() || pair.departureLeg?.arrivalAcStand?.toLowerCase() === this.filterService.filterDataSubject?.value?.aircraftStand?.toLowerCase() || pair.arrivalLeg?.departureAcStand?.toLowerCase() === this.filterService.filterDataSubject?.value?.aircraftStand?.toLowerCase() || pair.arrivalLeg?.arrivalAcStand?.toLowerCase() === this.filterService.filterDataSubject?.value?.aircraftStand?.toLowerCase()) : true)
      );
      this.hasNextPage = list.length > this.pageSize;
      let newList = list;
      if (newList.length === this.pageSize + 1) {
        newList = list.slice(0, this.pageSize);
      }
      newList.sort((a, b) => {
        if(a.turnaround?.currentState === 'IN_PROGRESS' && b.turnaround?.currentState !== 'IN_PROGRESS') return 1;
        if(a.turnaround?.currentState !== 'IN_PROGRESS' && b.turnaround?.currentState === 'IN_PROGRESS') return -1;
        if(a.departureLeg.tod < b.departureLeg.tod) return 1;
        if(a.departureLeg.tod > b.departureLeg.tod) return -1;
      });
      this.fsDb.collection<IPairDetail>('pairs').doc(originalList[originalList.length - 1].id.toString()).get().pipe(take(1)).subscribe((result) => {
        this.firestoreLastSnapshot = result as any;
      });
      return newList.map((val) => {
        convertTimestamps(val);
        return val;
      })
    }));

    this.firestoreFlatPairSubscription = firestoreObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(item => {
      console.log('item:', item);
      this.firestoreFlatPairSubject.next(item);
    });
  }

  private generateFirestoreQuery(ref: CollectionReference, previous?: boolean, pairDetail?: IPairDetail): Query {
    if (this.firestoreFlatPairSubscription) {
      this.firestoreFlatPairSubscription.unsubscribe();
    }

    const filter: IFilter = this.filterService.filterDataSubject.getValue();

    //let firestoreQuery: Query = ref.where('eta', '<', new Date(filter.dateTimeMoment.unix() * 1000)).where('airportCode', '==', filter.airport);
    let firestoreQuery: Query = ref;
    if (this.userService.userSubject.value.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
      filter.station = this.userService.userSubject.value?.location;
      firestoreQuery = ref.where('airportCode', '==', filter.station);
    }

    // if(filter.gate) {
    //   firestoreQuery = firestoreQuery.where('gate', '==', filter.gate);
    // }
    firestoreQuery = firestoreQuery.orderBy('eta', 'desc');


    if (pairDetail) {
      console.log('has flatpair: ', pairDetail);
      console.log('previous: ', previous);

      if (this.firestoreLastSnapshot) {
        if (!previous) {
          firestoreQuery = firestoreQuery.startAfter(this.firestoreLastSnapshot);
        } else {
          firestoreQuery = firestoreQuery.endAt(this.firestoreLastSnapshot);
        }
      }

    }

    // Adding +1 for pagination purposes
    if (!previous) {
      firestoreQuery = firestoreQuery.limit(this.pageSize + 1);
    } else {
      firestoreQuery = firestoreQuery.limitToLast(this.pageSize + 1);
    }
    return firestoreQuery;
  }

  getPairsOverview(page = 1): Observable<IPairOverview[]> {
    return this.userService.userSubject.pipe(switchMap((user) => {
      if (user) {
        const filters: IFilter = { ...this.filterService.filterDataSubject.value };
        if (filters.acRegistration && filters.acRegistration?.registration) {
          filters.acRegistration = filters.acRegistration.registration as any;
        }
        if(filters.dateFrom) {
          filters.dateFrom = ngbDateToDayjs(filters.dateFrom).format() as any;
        }
        if(filters.dateTo) {
          filters.dateTo = ngbDateToDayjs(filters.dateTo).format() as any;
        }
        if (user.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT) {
          filters.station = this.userService.userSubject.value.location;
        }

        return this.httpClient.post(`${environment.api_base_url}pairs/mobile-overview/${page}`, { isActive: true, filters: filters  }, {}).pipe(
          map((item: IResponse<IPairOverview[]>) => {
            if(item?.isError){
              console.log(item.message);
            }
            return item?.data || [];
          }),
          catchError((error) => {
            console.log(error);
            return of([]);
          })
        );
      } else {
        return of();
      }
    }));
  }

  getPairDetail(id: number): Observable<IPairDetail> {
    return this.httpClient.get(`${environment.api_base_url}pairs/detail/${id}`, {}).pipe(
      map((item: IResponse<IPairDetail>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  getPairs(filter: Searchable<IPairsModel> = { isActive: true }): Observable<IPairsModel[]> {
      return this.httpClient.post(`${environment.api_base_url}pairs/search`, filter, {}).pipe(
        map((item: IResponse<IPairsModel[]>) => {
          if(item?.isError){
            console.log(item.message);
          }
          return item?.data || [];
        }),
        catchError((error) => {
          console.log(error);
          return of([]);
        })
      );
  }
}
