<div class="d-flex flex-column gap-2">
  <div class="p-3">
    <div class="col-12">
      <object data="../../../assets/img/Avitium_Ramp_Watch_Logo.svg" type="image/svg+xml" alt="avitium ramp watch logo" width="100%" height="100px">
        <!-- Für Browser ohne SVG-Unterstützung -->
      </object>
    </div>
  </div>
  <div class="h-divider"></div>
  <div class="d-flex flex-column gap-2 p-3">
    <div class="col-12">
      <div class="d-flex flex-column gap-2">
        <div>
          <label for="userInput" class="form-label dark-blue roboto-font">E-mail</label>
          <input type="text" class="form-control" id="userInput" [(ngModel)]="email" data-test="email-input">
        </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-3" *ngIf="error || message">
      <p>
        <ngb-alert *ngIf="error" type="danger" [dismissible]="false">
          <div class="roboto-font"><strong>Error!</strong> {{ error }}</div>
        </ngb-alert>
        <ngb-alert *ngIf="message" type="info" [dismissible]="false">
          <div class="roboto-font"><strong>Info!</strong> {{ message }}</div>
        </ngb-alert>
      </p>
    </div>
    <div class="col-8 m-auto">
      <div class="d-flex flex-column gap-3">
        <button class="btn button-blue" [disabled]="!email.match('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')" (click)="reset()">
          <ng-container *ngIf="!isBusy; else loading">
            Reset password
          </ng-container>
          <ng-template #loading>
            <app-loading-spinner></app-loading-spinner>
          </ng-template>
        </button>

        <button class="btn button-blue" routerLink=".." data-test="back-button">
            Back to login
        </button>
      </div>
    </div>
  </div>
