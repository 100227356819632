import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ITurnaroundControl } from '../shared/models/turnaround-control.model';
import { catchError, map, Observable, of } from 'rxjs';
import { IResponse } from '../shared/models/IResponse.model';
import { IPairTurnaroundStatesModel } from '../shared/models/pair-turnaround-states.model';
import { Searchable } from '../shared/utils/searchable.type';

@Injectable({
  providedIn: 'root'
})
export class TurnaroundService {

  constructor(private httpClient: HttpClient) { }

  startTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/start`, data);
  }

  pauseTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/pause`, data);
  }

  finishTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/finish`, data);
  }

  resumeTurnaround(pairId: number) {
    const data: ITurnaroundControl = {
      pairId,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/resume`, data);
  }

  extendTurnaroundRequest(pairId: number, time: number, reason: string) {
    const data: ITurnaroundControl = {
      extensionInMinutes: time,
      pairId,
      reason,
    };
    return this.httpClient.post(`${environment.api_base_url}turnaround/etd-extension/request`, data);
  }

  getTurnarounds(filter: Searchable<IPairTurnaroundStatesModel> = { isActive: true }): Observable<IPairTurnaroundStatesModel[]> {
    return this.httpClient.post(`${environment.api_base_url}pair-turnaround-states/search`, filter).pipe(
      map((item: IResponse<IPairTurnaroundStatesModel[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
