import { IPairDetail } from '../models/pair-detail.model';
import { IPairOverview, isPairOverview } from '../models/pair-overview.model';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import {ILegsModel} from "../models/legs.model";

export function extractSeatingConfigurations(text: string): string[] {
  const seatingConfigurationRegex = /([A-Z][0-9]{1,3})/g;
  return text.split(seatingConfigurationRegex).filter((el) => el !== '');
}

export function getLatestArrivalTime(pair: IPairDetail | IPairOverview, arrivalLeg = true): Date {
  if (isPairOverview(pair)) {
    return pair.ata || pair.eta || pair.sta;
  }
  if (arrivalLeg) {
    return pair.arrivalLeg?.ata || pair.arrivalLeg?.eta || pair.arrivalLeg?.sta;
  }
  return pair.departureLeg?.ata || pair.departureLeg?.eta || pair.departureLeg?.sta;
}

export function getLatestDepartureTime(pair: IPairDetail | IPairOverview, arrivalLeg = true): Date {
  if (isPairOverview(pair)) {
    return pair.atd || pair.etd || pair.std;
  }
  if (arrivalLeg) {
    return pair.arrivalLeg?.atd || pair.arrivalLeg?.etd || pair.arrivalLeg?.std;
  }
  return pair.departureLeg?.atd || pair.departureLeg?.etd || pair.departureLeg?.std;
}

export function ngbDateToDayjs(date: NgbDateStruct): dayjs.Dayjs {
  if (!date) {
    return null;
  }
  return dayjs.utc([date.year, date.month - 1, date.day]);
}

export function ngbTimeToDayjs(time: NgbTimeStruct): dayjs.Dayjs {
  if (!time) {
    return null;
  }
  return dayjs.utc().hour(time.hour).minute(time.minute).second(time.second).startOf('second');
}


export function dayjsToNgbDate(date: dayjs.Dayjs): NgbDateStruct {
  if (!date) {
    return null;
  }
  return {day: date.date(), month: date.month() + 1, year: date.year()};
}

export function dayjsToNgbTime(date: dayjs.Dayjs): NgbTimeStruct {
  if (!date) {
    return null;
  }
  return {hour: date.hour(), minute: date.minute(), second: date.second() };
}

export function minutesToHHmm(actualMinutes:number): string {
  const hours = Math.floor(actualMinutes / 60);
  const minutes = actualMinutes % 60;
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return dayjs(formattedTime, 'HH:mm').format('HHmm');
}

export function getTimestamp(): string {
  return dayjs.utc().format('DD_MM_YYYY_HH_mm_ss_SSS');
}

export function calculateETA(leg: ILegsModel, atdDate: NgbDateStruct, atdTime: NgbTimeStruct, totTime: NgbTimeStruct, etd: Date): string {
  const hasAtd = atdDate?.year && atdDate?.month && atdDate?.day && atdTime?.hour !== undefined && atdTime.minute !== undefined;
  if (!etd && !hasAtd) {
    return dayjs(leg.sta).utc().format('HH:mm');
  }
  const blockTime = dayjs(leg.sta).diff(leg.std, 'minutes');
  if (totTime?.hour !== undefined && totTime?.minute !== undefined) {
    return ngbTimeToDayjs(totTime).add(blockTime, 'minutes').subtract(10, 'minutes').format('HH:mm');
  } else if (hasAtd) {
    const atdTimeSplit = ngbTimeToDayjs(atdTime).format('HH:mm').split(':');
    let dateObj = ngbDateToDayjs(atdDate).hour(Number(atdTimeSplit[0])).minute(Number(atdTimeSplit[1])).add(blockTime, 'minutes');
    return dateObj.format('HH:mm');
  } else if (etd) {
    return dayjs(etd).utc().add(blockTime, 'minutes').format('HH:mm');
  }
}

export function ngbDateToFormat(date: NgbDateStruct, format = 'DD.MM.YYYY'): string {
  if (!date) {
    return '';
  }
  return dayjs.utc([date.year, date.month - 1, date.day]).format(format);
}

export function calculateETADate(leg: ILegsModel): dayjs.Dayjs {
  if (!leg.etd && !leg.atd) {
    return dayjs(leg.sta).utc();
  }
  const blockTime = dayjs(leg.sta).diff(leg.std, 'minutes');
  if (leg.takeOffTime) {
    return dayjs(leg.takeOffTime).utc().add(blockTime, 'minutes').subtract(10, 'minutes');
  } else if (leg.atd) {
    return dayjs(leg.atd).utc().add(blockTime, 'minutes');
  } else if (leg.etd) {
    return dayjs(leg.etd).utc().add(blockTime, 'minutes');
  }
}

export function objectsAreEqual(a: any, b: any) {
  if (a === b) return true;

  if (typeof a != 'object' || a === null || typeof b != 'object' || b === null)
    return false;

  let keysA = Object.keys(a), keysB = Object.keys(b);

  if (keysA.length != keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key)) return false;
    if (typeof a[key] === 'object' && a[key] !== null && typeof b[key] === 'object' && b[key] !== null) {
      if (!objectsAreEqual(a[key], b[key])) return false;
    }
    else if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}
