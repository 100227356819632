import { Component, Input, OnInit } from '@angular/core';
import { LoadingContainerType } from '../../../../../shared/enums/loading-container-type.enum';

@Component({
  selector: 'app-loading-instruction',
  templateUrl: './loading-instruction.component.html',
  styleUrls: ['./loading-instruction.component.scss']
})
export class LoadingInstructionComponent implements OnInit {
  @Input() containerType: LoadingContainerType;
  @Input() compartment: number;
  @Input() position: number;
  @Input() weight: number;
  @Input() side: 'R' | 'L' | undefined;
  @Input() staticText: string;
  constructor() { }

  ngOnInit(): void {
  }

  get formattedHTML(): string {
    if (!this.staticText || !this.weight) {
      return '';
    }
    return this.staticText.replace('{weight}','<strong>' + this.weight.toString() + '</strong>');
  }

  public readonly LoadingContainerType = LoadingContainerType;
}
