<ng-container *ngIf="user">
<div class="d-flex flex-column mt-4 gap-3 justify-content-center text-center dark-blue p-3 main">
  <app-user-photo textColor="#002060" [user]="this.user" [size]="90" fontSize="1.5rem" fontWeight="600"></app-user-photo>
  <div class="name" data-test="contact-full-name"> {{ user.firstname }} {{ user.lastname }}</div>
  <div class="role" data-test="role-location">{{ user.role | i18nSelect: UserRoles.DISPLAY_TITLE_MAP }} ({{user.location}})</div>
  <div class="d-flex justify-content-between mt-2" [ngClass]="{ 'own-user': ownUser }">
    <a class="box contact-box" [href]="'sms:' + user.phone" data-test="sms-box">
      <div class="img">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-chat-fill" viewBox="0 0 16 16">
          <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z"/>
        </svg>
      </div>
      <div class="contact-method">SMS</div>
    </a>

    <a class="box contact-box" [href]="'tel:' + user.phone" data-test="call-box">
      <div class="img">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-telephone-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
        </svg>
      </div>
      <div class="contact-method">Call</div>
    </a>

    <div class="box contact-box" (click)="openChat()" data-test="chat-box">
      <div class="img">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-chat-right-dots-fill" viewBox="0 0 16 16">
          <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
      </div>
      <div class="contact-method">Chat</div>
    </div>

    <a class="box contact-box" [href]="'mailto:' + user.email" data-test="email-box">
      <div class="img">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#4285F6" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
          <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
          <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
        </svg>
      </div>
      <div class="contact-method">E-mail</div>
    </a>
  </div>
  <div class="d-flex flex-column gap-2 box text-start">
    <div class="title">
      Telephone
    </div>
    <div class="blue-text fs-14" data-test="user-phone">
      {{ user.phone }}
    </div>
  </div>

  <div class="d-flex flex-column gap-2 box text-start">
    <div class="title">
      Email
    </div>
    <div class="blue-text fs-14" data-test="user-email">
      {{ user.email }}
    </div>
  </div>
</div>
</ng-container>
