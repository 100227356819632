<div class="modal-header">
  <h4 class="modal-title poppins" id="modal-basic-title">Filter pairs</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.close(false)" data-test="filters-close-btn">
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form" class="d-flex flex-column filter-form">
    <div class="d-flex justify-content-between" *ngIf="filterCategory === FilterCategory.DAY_OF_OPERATION">
      <label for="timespan">Time Span:</label>
      <div class="input-group">
        <select class="form-select roboto-font" id="timespan" formControlName="timespan" [ngClass]="{ 'blue-option': form.value.timespan !== 'null' && form.value.timespan !== null }" data-test="filter-timespan-select">
          <option [ngValue]="null">Time Span</option>
          <option value="8Hours">next 8 hours</option>
          <option value="12Hours">next 12 hours</option>
          <option value="24Hours">next 24 hours</option>
        </select>
      </div>
    </div>
<!--    <div class="d-flex justify-content-between">-->
<!--      <label for="acType" class="align-self-center">AC Type:</label>-->
<!--      <div class="input-group">-->
<!--        <select id="acType" class="form-select roboto-font" formControlName="acType" [ngClass]="{ 'blue-option': form.value.timespan !== 'null' && form.value.timespan !== null }">-->
<!--          <option value="null" selected>No filter</option>-->
<!--          <option *ngFor="let acType of getAvailableAcTypes" [ngValue]="acType">{{acType?.iata}}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
    <div class="d-flex justify-content-between">
      <label for="acRegistration" class="align-self-center">AC Registration:</label>
      <div class="input-group">
        <select id="acRegistration" class="form-select roboto-font" formControlName="acRegistration" [ngClass]="{ 'blue-option': form.value.timespan !== 'null' && form.value.timespan !== null }" data-test="ac-reg-select">
          <option [ngValue]="null" selected>No filter</option>
          <option *ngFor="let acRegistration of getAvailableAcRegistrations" [ngValue]="acRegistration" [attr.data-test]="'registration-' + acRegistration.registration">{{acRegistration?.registration}}</option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <label for="flightNumber" class="align-self-center">Flight Number:</label>
      <div class="input-group">
        <input formControlName="flightNumber" id="flightNumber" class="form-control" data-test="filters-flight-nr">
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <label for="passengerGate" class="align-self-center">Passenger Gate:</label>
      <div class="input-group">
        <input formControlName="passengerGate" id="passengerGate" class="form-control" data-test="filters-passenger-gate">
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <label for="aircraftStand" class="align-self-center">Aircraft Stand:</label>
      <div class="input-group">
        <input formControlName="aircraftStand" id="aircraftStand" class="form-control" data-test="filters-ac-stand">
      </div>
    </div>
    <ng-container *ngIf="filterCategory !== FilterCategory.DAY_OF_OPERATION">
      <div class="d-flex justify-content-between">
        <label for="dateFrom" class="align-self-center">Date from:</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="dateFrom" id="dateFrom" ngbDatepicker #to="ngbDatepicker" [autoClose]="true" (dateSelect)="to.close()" data-test="filters-date-from"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn light-blue-border"
                    (click)="to.toggle()" type="button" data-test="date-calendar-button"></button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <label for="dateTo" class="align-self-center">Date to:</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="dateTo" id="dateTo" ngbDatepicker #from="ngbDatepicker" [autoClose]="true" (dateSelect)="from.close()" data-test="filters-date-to" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary demo-icon button-appendix icon-icons8-calendar date-selector-btn light-blue-border"
                    (click)="from.toggle()" type="button" data-test="date-calendar-button"></button>
          </div>
        </div>
      </div>
    </ng-container>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn custom-button-1" [disabled]="form?.invalid" (click)="okClicked()" data-test="filters-submit-btn">
    <span>OK</span></button>
</div>
