import { IPairDetail, isPairDetail } from '../models/pair-detail.model';
import { IPairOverview, isPairOverview } from '../models/pair-overview.model';
import { IPairsModel } from '../models/pairs.model';
import { ILegsModel } from '../models/legs.model';
import * as dayjs from 'dayjs';

export class TestTagsUtils {
  public static getPairTestTag(pair: IPairDetail | IPairOverview) {
    if (!pair || !pair.id) {
      return 'null-null';
    }
    return TestTagsUtils.getPairArrivalTestTag(pair) + '---' + TestTagsUtils.getPairDepartureTestTag(pair);
  }

  private static getPairArrivalTestTag(pair: IPairDetail | IPairsModel | IPairOverview) {
    let arrivalLeg: ILegsModel;
    if (isPairDetail(pair)) {
      arrivalLeg = pair?.arrivalLeg;
    } else if(isPairOverview(pair)) {
      if (!pair.arrivalLegStd) {
        return 'null';
      }
      return pair.airlineDesignator + pair.flightNumberArriving + '_' + dayjs.utc(pair.arrivalLegStd).format('DDMMMYY') + '_' + pair.arrivalLegDepartureStation + '_' + pair.arrivalLegArrivalStation;
    } else {
      arrivalLeg = pair?.__arrivalLegModel__;
    }
    if (arrivalLeg) {
      return this.getLegTestTag(arrivalLeg);
    }
  }

  private static getPairDepartureTestTag(pair: IPairDetail | IPairsModel | IPairOverview) {
    let departureLeg: ILegsModel;
    if (isPairDetail(pair)) {
      departureLeg = pair?.departureLeg;
    } else if(isPairOverview(pair)) {
      if (!pair.departureLegStd) {
        return 'null';
      }
      return pair.airlineDesignator + pair.flightNumberDeparting + '_' + dayjs.utc(pair.departureLegStd).format('DDMMMYY') + '_' + pair.departureLegDepartureStation + '_' + pair.departureLegArrivalStation;
    } else {
      departureLeg = pair?.__departureLegModel__;
    }
    if (departureLeg) {
      return this.getLegTestTag(departureLeg);
    }
  }

  public static getLegTestTag(leg: ILegsModel) {
    if (!leg) {
      return 'null';
    }
    return leg.airlineDesignator + leg.flightNumber + '_' + dayjs.utc(leg.std).format('DDMMMYY') + '_' + leg.departureStation + '_' + leg.arrivalStation;
  }

}
