<div class="start-turnaround-container">
  <!-- <div class="img-container">
    <img src="/assets/img/acAtRamp.png"/>
  </div> -->
  <div class="text-container roboto-font dark-blue">

      "Aircraft is approaching the gate / stand please confirm the beginning of the turnaround when the door opens"

  </div>
  <div class="controls-container">
    <button [disabled]="disabled || startAccess !== Access.RW" class="btn button-blue roboto-font" (click)="startClicked.emit()" data-test="start-turnaround">Start Turnaround</button>
  </div>
</div>
