import {Injectable} from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../shared/models/user.model';
import { environment } from '../../environments/environment';
import { IResponse } from '../shared/models/IResponse.model';
import { of } from 'rxjs/internal/observable/of';
import { Arrayable } from '../shared/utils/arrayable.type';
import { IGenericContainerObject } from '../shared/models/genericContainerObject.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { take } from 'rxjs/operators';
import {StaticUserGroupConstants} from "../shared/constants/static-user-group.constants";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  userSubject: BehaviorSubject<IUser> = new BehaviorSubject(null);
  users: IGenericContainerObject<IUser> = {};
  availableChatUsers = new BehaviorSubject<IUser[]>([]);

  constructor(private db: AngularFirestore, private httpClient: HttpClient, private auth: AngularFireAuth) {
    this.userSubject.subscribe((user) => {
      if (user) {
        this.searchUsers().subscribe((results) => {
          for (const result of results) {

            this.users[result.id] = result;
          }
          this.availableChatUsers.next(results.filter((result) => result.id !== this.userSubject.value.id && result.location === user.location && (result.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT || result.userGroup === StaticUserGroupConstants.STR_TO_ID.RAMP_AGENT)));
        });
      } else {
        this.availableChatUsers.next([]);
      }
    });
    this.auth.authState.pipe(take(1)).subscribe((user) => {
      this.setUserByUid(user?.uid);
    });
  }

  getUser(): Observable<IUser> {
    return this.userSubject.asObservable();
  }

  setUser(user: IUser) {
    if (user) {
      user.userGroup = Number(user.userGroup) || 0;
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
    }
  }

  setUserByUid(uid: string) {
    if (!uid) {
      return;
    }

    this.db.collection('users').doc(uid).get().subscribe(item => {
      if (item) {
        const data: IUser = item.data();
        data.firstname = data.firstname.charAt(0).toUpperCase() + data.firstname.slice(1);
        data.lastname = data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1);
        this.setUser(data);
      }
    });
  }

  getUserById(userId: number): Observable<IUser> {
    return this.httpClient.get(`${environment.api_base_url}users/${userId}`, { headers: { authorization : '123'}}).pipe(
      map((item: IResponse<IUser>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || {};
      }),
      catchError((error) => {
        console.log(error);
        return of({});
      })
    );
  }

  searchUsers(filter: Arrayable<IUser> = { isActive: true }): Observable<IUser[]> {
    return this.httpClient.post(`${environment.api_base_url}users/search`, filter,{ headers: { authorization : '123'}}).pipe(
      map((item: IResponse<IUser[]>) => {
        if(item?.isError){
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      })
    );
  }
}
