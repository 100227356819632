<div class="modal-header">
    <div class="modal-title">Edit Processes</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal('Cross click')"></button>
</div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="d-flex flex-column border-bottom gse-container">
        <div *ngFor ="let gse of gses" class="d-flex align-items-center border-bottom gse-row skip-invalid-border" [formGroupName]="gse.id">
          <div class="form-check form-switch">
            <input  formControlName="inUse" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="pairProcessIdArray.includes(gse.processId)">
          </div>
          <label class="form-check-label flex-grow-1" for="flexSwitchCheckChecked">{{form.controls[gse.id].value.title}}</label>
          <div class="icon-container">
            <i [class]="'demo-icon ' + getIconClass(gse.gseIdentifier) + ' fs-30'"></i>
          </div>
          <input class="form-control amount-input" formControlName="amount" type="number" value="{{findAmount(gse.processId)}}">
        </div>

      </div>
      <div class="d-flex justify-content-between mt-2">
        <button class="btn flex-basis-25 cancel-btn" (click)="closeModal('')">Cancel</button>
        <button class="btn button-blue flex-basis-25" (click)="saveForm()">Save</button>
      </div>
    </form>
   </div>

