import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ILoadingInstruction } from '../loading-instruction.model';

@Component({
  selector: 'app-edit-loading-instruction-modal',
  templateUrl: './edit-loading-instruction-modal.component.html',
  styleUrls: ['./edit-loading-instruction-modal.component.scss']
})
export class EditLoadingInstructionModalComponent implements OnInit {

  loadingInstruction: ILoadingInstruction;
  position: number = 0;
  compartment: number = 0;
  weight: number = 0;
  constructor(private activeModal: NgbActiveModal) { }

  closeModal(reason: any) {
    this.activeModal.dismiss(reason);
  }

  ngOnInit(): void {
    this.position = this.loadingInstruction?.position;
    this.compartment = this.loadingInstruction?.compartment;
    this.weight = this.loadingInstruction?.weight;
  }

  saveForm() {
    this.loadingInstruction.position = this.position;
    this.loadingInstruction.weight = this.weight;
    this.loadingInstruction.compartment = this.compartment;
    this.closeModal(true);
  }

}
