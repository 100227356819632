import {Injectable} from '@angular/core';
import {IBasicPermissionsModel} from "../shared/models/basic-permissions.model";
import {IBasicPermissionApplicationModel} from "../shared/models/basic-permission-application.model";
import {IUser} from "../shared/models/user.model";
import {forkJoin} from "rxjs";
import {BasicPermissionsService} from "./basic-permissions.service";
import {Access, PermissionUIMasks} from "../shared/enums/permissions.enums";
import {UserService} from "./user.service";
import {AirportsService} from "./airports.service";
import {IAirport} from "../shared/models/airport.model";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permissions: IBasicPermissionsModel[] = [];
  permissionApplications: IBasicPermissionApplicationModel[] = [];
  permissionsLoaded = false;
  airports: IAirport[];
  user: IUser;

  airportsThatCanSee: IAirport[] = [];

  constructor(private basicPermissionService: BasicPermissionsService, private authService: UserService, private airportService: AirportsService) {
    this.authService.userSubject.subscribe((user: IUser) => {
      this.user = user;
      if (user) {
        forkJoin([this.basicPermissionService.fetchBasicPermissions({
          userGroupId: user.userGroup,
          isActive: true
        }), this.basicPermissionService.fetchBasicPermissionsApplication({
          userGroupId: user.userGroup,
          isActive: true
        }), this.airportService.fetchAirports()]).subscribe(([basicPermissions, basicPermissionApplication, airports]) => {
          this.permissions = basicPermissions;
          this.permissionApplications = basicPermissionApplication;
          this.airports = airports;
          this.onPermissionsLoaded();

        });
        return;
      }
      this.permissionsLoaded = false;
      this.permissionApplications = [];
      this.permissions = [];
    });
  }

  async getPermissionAccess(mask: PermissionUIMasks, airport?: number): Promise<Access> {
    if (!this.permissionsLoaded) {
      while (!this.permissionsLoaded) {
        await new Promise((res) => setTimeout(res, 50));
      }
    }

    if (airport) {
      const entry = this.permissionApplications.find((perm) => perm.name.includes(mask) && (perm.airportId === airport || perm.airportId === null || perm.airportId === 0 && airport === this.airports.find((airport) => airport.iata === this.authService.userSubject.value.location).id));
      if (!entry) {
        return null;
      }
      const permission = this.permissions.find((perm) => perm.id === entry.basicPermissionId);
      return permission?.access ?? null;
    }
    const entry = this.permissions.find((perm) => perm.uiMask === mask);
    return entry?.access ?? null;
  }

  async onPermissionsLoaded() {
    this.airportsThatCanSee = [];
    const airportPermission = this.permissions.filter((perm) => perm.uiMask === PermissionUIMasks.MOBILE_FLIGHT_INFO && perm.userGroupId === this.user.userGroup && !!perm.access);
    const permIds = airportPermission.map((perm) => perm.id);
    const permApplication = this.permissionApplications.filter((perm) => permIds.includes(perm.basicPermissionId));
    if (permApplication.find((perm) => perm.airportId === null)) {
      const airport: IAirport = {
        id: 0,
        iata: 'ALL'
      };
      this.airportsThatCanSee.push(airport);
      this.permissionsLoaded = true;
      return;
    }
    for (const application of permApplication) {
      const airportModel = this.airports.find((airport) => airport.id === application.airportId);
      if (airportModel) {
        this.airportsThatCanSee.push(airportModel);
      }
    }
    this.permissionsLoaded = true;
  }
}
