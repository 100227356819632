<div class="page-container">

  <div class="list-container mt-3">
    <div class="row filter-container">
      <div style="text-align: right;" class="header-funnel me-2" (click)="openFilterModal()" data-test="open-filters">
        Filter
      </div>
    </div>
    <app-turnarounds-pair-list [pairList]="pairList" [isBusy]="isBusy"></app-turnarounds-pair-list>
  </div>
  <div class="pagination">
    <button class="btn button-blue" [disabled]="page === 1 || isBusy" (click)="previousPage()" data-test="previous-page">Previous</button>
    <button class="btn button-blue" [disabled]="!hasNextPage || isBusy" (click)="nextPage()" data-test="next-page">Next</button>
  </div>
</div>
