import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {IResponse} from '../shared/models/IResponse.model';
import {ToastService} from './toast.service';
import {IBasicPermissionsModel} from "../shared/models/basic-permissions.model";
import {IBasicPermissionApplicationModel} from "../shared/models/basic-permission-application.model";

@Injectable({
  providedIn: 'root'
})
export class BasicPermissionsService {


  constructor(private httpClient: HttpClient, private toastService: ToastService) {
  }

  fetchBasicPermissions(filter: Partial<IBasicPermissionsModel> = {isActive: true}): Observable<IBasicPermissionsModel[]> {
    return this.httpClient.post(`${environment.api_base_url}basic-permissions/search`, filter, {}).pipe(
      map((item: IResponse<IBasicPermissionsModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }



  fetchBasicPermissionsApplication(filter: Partial<IBasicPermissionApplicationModel> = {isActive: true}): Observable<IBasicPermissionApplicationModel[]> {
    return this.httpClient.post(`${environment.api_base_url}basic-permission-application/search`, filter, {}).pipe(
      map((item: IResponse<IBasicPermissionApplicationModel[]>) => {
        if (item?.isError) {
          console.log(item.message);
        }
        return item?.data || [];
      }),
      catchError((error) => {
        console.log(error);
        return of([]);
      }),
    );
  }
}
