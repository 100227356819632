import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { IPairsProcessesModel } from '../../models/pair-processes.model';
import { IProcessesModel } from '../../models/processes.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefinedGses } from '../../constants/defined-gses';
import * as dayjs from 'dayjs';
import {forkJoin, Observable, Subject} from 'rxjs';
import { PairProcessesService } from '../../../services/pair-processes.service';
import { IGsesModel } from '../../models/gses.model';
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-ramp-view-processes',
  templateUrl: './ramp-view-processes.component.html',
  styleUrls: ['./ramp-view-processes.component.scss']
})
export class RampViewProcessesComponent implements OnInit, OnDestroy {

  @Output() closeModalAction = new EventEmitter<string>();
  gses: IGsesModel[];
  pairProcesses: IPairsProcessesModel[];
  processes: IProcessesModel[];
  pairId: number;
  form: FormGroup;
  user: any;
  airportId: number;
  acTypeId: number;
  pairProcessIdArray = [];
  unsubscribe$ = new Subject<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private pairProcessesService: PairProcessesService,
    ) { }



  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    for (const pairProcess of this.pairProcesses) {
      this.pairProcessIdArray.push(pairProcess.processId);
    }
    this.createForm();
  }

  findAmount(processId) {
    if(this.pairProcessIdArray.includes(processId)){
      return this.pairProcesses.find((pairProcess) => pairProcess.processId === processId).amount
    } else {
      return 0;
    }
  }

  createForm() {
    this.form = this.fb.group({});

    for (const gse of this.gses) {
      this.form?.addControl(String(gse.id), this.fb.group({
        processId: gse.processId,
        title: [this.processes.find((p) => p.id === gse.processId)?.title, Validators.required],
        amount: [this.findAmount(gse.processId), Validators.compose([Validators.required, Validators.pattern('^[1-9]([0-9])*')])],
        inUse: [this.pairProcessIdArray.includes(gse.processId)],
      }));
    }

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
      console.log('Changed');
      for (const ctrl in this.form.controls) {
        if(this.form.controls[ctrl].value.inUse && this.form.controls[ctrl].value.amount === 0) {
          this.form.controls[ctrl].patchValue({
            amount: 1,
          }, { emitFalse: false, onlySelf: true });
        }
      }
    });
  }

  async saveForm() {
    let processInForm;
    const pairProcesesToBeUpdated: IPairsProcessesModel[] = [];
    for (const processt in this.form.value) {

      processInForm = this.form.controls[processt].value;

      const foundPairProcess = this.pairProcesses.find((pairProcess) => pairProcess.processId === processInForm.processId);

      if (processInForm.inUse === true && foundPairProcess === undefined) {
        const gseId = this.gses.find((gse) => gse.processId === processInForm.processId).id
        pairProcesesToBeUpdated.push({
          id: undefined,
          amount: processInForm.amount,
          gseId: gseId,
          pairId: this.pairId,
          processId: processInForm.processId,
          startTime: null,
          endTime: null,
          isActive: true,
          lastChangedAt: dayjs().utc().toDate(),
          lastChangedBy: this.user.id,
        });
      } else if (processInForm.inUse === false && foundPairProcess) {
        pairProcesesToBeUpdated.push({
          id: foundPairProcess.id,
          amount: foundPairProcess.amount,
          gseId: foundPairProcess.gseId,
          pairId: foundPairProcess.pairId,
          processId: foundPairProcess.processId,
          startTime: foundPairProcess.startTime,
          endTime: foundPairProcess.endTime,
          isActive: false,
          lastChangedAt: dayjs().utc().toDate(),
          lastChangedBy: this.user.id,
        });

      } else if (processInForm.inUse === true && foundPairProcess) {
        //check if amount has changed
        if (foundPairProcess.amount !== Number(processInForm.amount)) {
          pairProcesesToBeUpdated.push({
            id: foundPairProcess.id,
            amount: Number(processInForm.amount),
            gseId: foundPairProcess.gseId,
            pairId: foundPairProcess.pairId,
            processId: foundPairProcess.processId,
            startTime: foundPairProcess.startTime,
            endTime: foundPairProcess.endTime,
            isActive: true,
            lastChangedAt: dayjs().utc().toDate(),
            lastChangedBy: this.user.id,
          });
        }
      }
    }
    if (pairProcesesToBeUpdated.length) {
      const observables: Observable<any>[] = [];
      for (const p of pairProcesesToBeUpdated) {
        observables.push(this.pairProcessesService.savePairProcess(p));
      }
      forkJoin(observables).subscribe((result) => {
        this.closeModal('');
      });
      return;
    }
    this.closeModal('');
  }

  closeModal(reason: string) {
    this.activeModal.dismiss(reason);
  }

  getIconClass(gseTypeName: string): string {
    const className = DefinedGses[gseTypeName].class;
    return className || '';
  }
}
